import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81db0950"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "part-details-feedback-message-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.showBackButton && $setup.isInputFocused)
      ? (_openBlock(), _createBlock($setup["NButton"], {
          key: 0,
          class: "toggle-to-actions-button",
          circle: true,
          text: true,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleToActions')))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ChevronLeftInCircle"])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock($setup["UserAvatar"], {
          key: 1,
          id: $setup.user.avatar?.id,
          size: 24,
          name: $setup.authorName
        }, null, 8, ["id", "name"])),
    _createVNode($setup["NFormItem"], {
      rule: $setup.messageRule,
      ref: "messageRef",
      class: _normalizeClass({ error: $setup.isMessageValidationError })
    }, {
      default: _withCtx(() => [
        _createVNode($setup["AutosizeTextarea"], {
          class: "message-input",
          modelValue: $setup.inputValue,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.inputValue) = $event)),
          placeholder: $props.placeholder,
          minHeight: 24,
          "validation-error": $setup.isMessageValidationError,
          inputFiles: $props.inputFiles,
          fixedButton: false,
          withGallery: false,
          onFocus: _cache[3] || (_cache[3] = ($event: any) => ($setup.onInputFocus())),
          onBlur: _cache[4] || (_cache[4] = ($event: any) => ($setup.onInputBlur())),
          attachmentType: $setup.UploadFileType.MESSAGE_ATTACHMENTS,
          deleteFromComponentGallery: !$props.editing && $props.messageName === 'Message',
          withUploadImage: "",
          onFilesUploaded: $setup.filesUploaded,
          onDeleteFile: $setup.deleteFile
        }, {
          default: _withCtx(() => [
            _createVNode($setup["NButton"], {
              class: "send-message-button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.onMessageSubmit())),
              type: "info",
              round: "",
              disabled: $setup.isMessageValidationError || $setup.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode("Send")
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        }, 8, ["modelValue", "placeholder", "validation-error", "inputFiles", "attachmentType", "deleteFromComponentGallery"])
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}